<template>
  <div class="info">

    <van-popup v-model="replyShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="replySelect" value-key="label"  @confirm="replyConfirm"  @cancel="replyShow = false"/>
    </van-popup>

    <van-cell-group>
      <van-cell >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">握一握信息</span>
        </template>
      </van-cell>
      <van-cell title="所在小区"  :value="selectMsg.Community"/>
      <van-cell title="具体位置"  :value="selectMsg.houseName"/>
      <van-cell title="问题名称"  :value="selectMsg.problem"/>
    </van-cell-group>
    <div style="height: .2rem; background-color:#f5f5f5;"></div>

<!--    发给我的待回复状态-->
    <van-cell-group v-if="(tabIndex === '1' && status !== 3 && status !== 4 ) || dataForm.replyType">
      <van-cell >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">回复信息</span>
        </template>
      </van-cell>
      <van-cell title="回复问题" :value="selectMsg.reply" is-link @click="replyShow = status === 1"/>
    </van-cell-group>

<!--    社工介入-->
    <van-cell-group v-if="tabIndex === '0' && status === 3">
      <van-cell >
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">社区物业介入信息</span>
        </template>
      </van-cell>
      <van-cell title="处理人"  :value="selectMsg.solveName"/>
      <van-cell title="联系电话"  :value="selectMsg.solveMobile"/>
      <van-cell title="处理时间"  :value="selectMsg.solveTime"/>
      <van-cell title="处理状态"  :value="getStatus(selectMsg.solveStatus) || '未知'"/>
      <van-cell title="处理结果"  :value="selectMsg.solveResult" class="solveResult"/>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" style="padding-top: 10px" v-if="status !== 1 && status !== 2">
        <van-button type="info" size="large" round @click="goBack">返回</van-button>
      </van-col>
<!--      发给我的待回复状态-->
      <van-col :span="24" style="padding-top: 10px" v-if="tabIndex === '1' && status === 1">
        <van-button type="info" size="large" round @click="submitReply">确定回复</van-button>
      </van-col>
<!--      我发起的待回复状态-->
      <van-col :span="24" style="padding-top: 10px" v-if="tabIndex === '0' && status !== 3 && status !== 4">
        <van-button :color="dataForm.canChangeProblem ? '#ffa93a' : '#999'" size="large" round @click="submit('changeProblem')">转为问题,请社区物业介入</van-button>
      </van-col>
      <van-col :span="24" style="padding-top: 10px" v-if="tabIndex === '0' && status !== 4 && status !== 3">
        <van-button type="info" size="large" round @click="submit('isFinish')">问题已解决,完成握一握</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {getDictTree} from '@/utils/common'

export default {
  data () {
    return {
      id: '',
      status: '',
      tabIndex: '',
      selectMsg: {
        Community: '请选择',
        Building: '请选择',
        houseName: '未知',
        problem: '请选择',
        reply: '请选择',
        solveName: '暂无',
        solveMobile: '暂无',
        solveResult: '暂无',
        solveTime: '暂无',
        solveStatus: '暂无'
      },
      replySelect: [],
      replyShow: false,
      dataForm: {}
    }
  },
  computed:{...mapState(['gripData'])},

  methods: {
    getStatus (status) {
      switch (status) {
        case 0:
          return '无法处理'
        case 10:
          return '未处理'
        case 20:
          return '处理中'
        case 30:
          return '结束'
        case 40:
          return '结束'
        case 50:
          return '结束'
      }
    },
    goBack () {
      this.$router.back()
    },
    submit (name) {
      if (name === 'changeProblem' && this.dataForm.canChangeProblem === 0) {
        return this.$toast.fail('一个小时后才可以申请物业接入哦')
      }
      this.$dialog.confirm({message:'确定要提交吗'}).then(() => {
        this.dataForm[name] = 1
        this.$httpApp({
          url: this.$httpApp.adornUrl('/wxapp/future/communications/save'),
          method: 'post',
          data: this.$httpApp.adornData({...this.dataForm},false)
        }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success('已成功提交')
            this.$router.back()
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    submitReply () {
      if (!this.selectMsg.reply || this.selectMsg.reply === '请选择') {return this.$toast.fail('请先选择回复内容')}
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/future/communications/writeBack'),
        method: 'post',
        data: this.$httpApp.adornData({...this.dataForm},false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('回复成功')
          this.$router.back()
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    getInfo () {
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/future/communications/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          id: this.id
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectMsg = {
            Community: data.futureCommunications.subareaStr || '未知',
            houseName: data.futureCommunications.houseName || '未知',
            problem: data.futureCommunications.typeStr || '未知',
            reply: data.futureCommunications.replyTypeStr || '请选择',
            solveName:  data.futureCommunications.solveName || '暂无',
            solveMobile:  data.futureCommunications.solveMobile || '暂无',
            solveResult:  data.futureCommunications.solveResult || '暂无',
            solveTime:  data.futureCommunications.solveTime || '暂无',
            solveStatus: data.futureCommunications.solveStatus
          }
          this.dataForm = data.futureCommunications
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    replyConfirm (value) {
      if(value) {
        this.selectMsg.reply = value.label
        this.dataForm.replyType = value.value
      }
      this.replyShow = false
    },
    getReply () {
      getDictTree({code: 'futureCommunicationsReply', orgId: this.$orgId}, (res)=> {
        this.replySelect = res
      })
    },
  },
  created() {
    this.status = this.gripData.status
    this.id = this.gripData.id
    this.tabIndex = this.gripData.tabIndex
    this.getReply()
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.info {
  min-height: 100vh;
  background-color: #fff;
  padding-bottom: 100px;
}
.solveResult{
  ::v-deep  .van-cell__value{
   flex: 2;
  }
}
</style>
